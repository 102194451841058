<template>
  <v-container>

    <div class="box d-flex justify-center">
      <v-sheet
          class="py-8"
          color="error"
          elevation="4"
          width="600px"
      >
        <v-alert type="error" class="d-flex justify-center">
          <slot>{{ $route.meta.message }}</slot>
        </v-alert>
      </v-sheet>
    </div>

  </v-container>
</template>

<script>
export default {
  name: "AppError"
}
</script>

<style lang="sass" scoped>
.box
  margin-top: 180px
</style>
