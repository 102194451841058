import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store/index";
import vuetify from "./plugins/vuetify";
import highlight from "./plugins/highlight";
import "./plugins/base";
require("@/assets/custom.css");

Vue.config.productionTip = false;

new Vue({
  mounted() {
    store.dispatch("loadQuizData").catch((e) => {
      console.log(e);
      store.commit("setAppError", true);
    });
  },
  router,
  store,
  vuetify,
  highlight,
  render: (h) => h(App),
}).$mount("#app");
