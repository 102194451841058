const mutations = {
  // quiz
  storeQuiz(state, quiz) {
    state.quizzes = quiz
  },
  storeQuizQuestion(state, quizQuestion) {
    if (quizQuestion) {
      state.quizQuestion = quizQuestion
    }
  },
  setQuizFinish(state, finish) {
    state.quizFinish = finish
  },
  setSuccess(state, success) {
    state.isSuccess = success
  },
  setQuizDataLoaded(state, loading) {
    state.quizDataLoaded = loading
  },
  setAppError(state, error) {
    state.appError = error
  },
  setAppMode(state, mode) {
    if (mode === 'exam') {
      state.appMode = mode
    }
  },

  // question
  storeQuestionsIds(state, questionsIds) {
    if (Array.isArray(questionsIds)) {
      const questionsIdsObj = { ...questionsIds }
      state.questionsIds = questionsIdsObj
      state.quizQuestionsLength = Object.keys(questionsIdsObj).length
      return true
    }

    if (questionsIds instanceof Object) {
      state.questionsIds = questionsIds
      state.quizQuestionsLength = Object.keys(questionsIds).length
    }
  },
  incrementQuestionsCounter(state) {
    if (state.questionsCounter < Object.keys(state.questionsIds).length - 1) {
      state.questionsCounter++
    }
  },
  setQuestionsCorrect(state, id) {
    state.questionsCorrect.push(id)
  },
  setQuestionsFailed(state, id) {
    state.questionsFailed.push(id)
  },

  // result
  storeResults(state, results) {
    state.results = results
  },
  setEmail(state, email) {
    state.email = email
  },
}

export default mutations
