<template>
  <base-material-card :icon="score + '%'" color="cyan" :title="title" class="px-5 py-3">
    <v-simple-table>
      <!-- failed questions -->
      <template v-if="failedQuestionsByTopic">
        <thead v-if="Object.keys(failedQuestionsByTopic).length > 0">
          <tr>
            <th class="primary--text">Failed</th>
            <th class="primary--text"></th>
            <th class="primary--text">Answers</th>
            <!--<th class="primary--text">
              Explanation
            </th>-->
          </tr>
        </thead>

        <tbody>
          <tr v-for="(failedQuestion, i) in failedQuestionsByTopic" :key="i" cols="12">
            <td class="py-5">
              <VueShowdown :markdown="failedQuestion.question" flavor="github"
                :options="{ emoji: true, openLinksInNewWindow: true }" class="font-weight-medium" />

              <VueShowdown :markdown="failedQuestion.explanation" class="text--secondary" style="font-style: italic"
                flavor="github" :options="{ emoji: true, openLinksInNewWindow: true }" />
            </td>
            <td class="py-5">
              <div class="ma-4 d-flex align-start" v-if="!!failedQuestion.documentationLink">
                <a :href="failedQuestion.documentationLink" target="_blank">
                  Documentation
                </a>
              </div>
            </td>
            <td>
              <VueShowdown :markdown="failedQuestion.correctAnswers.toString()" flavor="github"
                :options="{ emoji: true, openLinksInNewWindow: true }" />
            </td>
            <!--<td>
              <vue-markdown :source="failedQuestion.explanation"></vue-markdown>
            </td>-->
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </base-material-card>
</template>

<script>
import _ from "lodash";
import { VueShowdown } from "vue-showdown";
export default {
  name: "ResultTopicTable",
  components: { VueShowdown },
  props: ["title", "questions"],
  computed: {
    failedQuestionsByTopic() {
      const filtered = _.filter(this.questions.failed, { topic: this.title });
      return filtered;
    },
    correctQuestionsByTopic() {
      const filtered = _.filter(this.questions.correct, { topic: this.title });
      return filtered;
    },
    score() {
      const correct = Object.keys(this.correctQuestionsByTopic).length;
      const failed = Object.keys(this.failedQuestionsByTopic).length;

      const total = correct + failed;
      const result = parseInt(((correct / total) * 100).toFixed());

      return Number.isNaN(result) ? 0 : result;
    },
  },
};
</script>

<style scoped></style>
