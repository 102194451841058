import _ from 'lodash'

const helpers = {
    getQuestionShuffled(question) {
    question.data.answers = _.shuffle(question.data.answers)
    
    return question.data
  }
}

export default helpers 