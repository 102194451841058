<template>
  <v-container style="width: 600px">
    <div class="mx-1 pa-16 rounded-lg elevation-2 box">
      <v-row class="text-center">
        <v-col cols="12">
          <h2 class="headline mb-3">
            <v-row
                class="fill-height"
                align-content="center"
                justify="center"
            >
              <v-col
                  class="subtitle-1 text-center"
                  cols="12"
              >
                <slot></slot>
              </v-col>
              <v-col cols="6">
                <v-progress-linear
                    color="deep-purple accent-4"
                    indeterminate
                    rounded
                    height="6"
                ></v-progress-linear>
              </v-col>
            </v-row>
          </h2>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "ProgressLoader"
}
</script>

<style lang="sass" scoped>
.box
  background: white
  margin-top: 180px
</style>
