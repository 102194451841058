import axios from "axios";
import router from "@/router";
import _ from "lodash";
import helpers from "@/plugins/helpers";

const actions = {
  async simulateCall() {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 10000);
    });
  },
  async loadQuizData({ commit }) {
    const host = process.env.VUE_APP_BACKEND_HOST;
    const token = router.currentRoute.query.token;

    let mode = router.currentRoute.query.mode;
    let email = router.currentRoute.query.email;
    let quizName = router.currentRoute.query.name;


    if (!token) {
      throw "Bad Queries";
    }

    if (process.env.VUE_APP_MODE !== "local") {
      try {
        const validationResp = await axios.get(
            `${host}/api/validation?&token=${encodeURIComponent(token)}`
        );
        const token_payload = validationResp.data ? validationResp.data.payload : null;
        if (!token_payload) {
            return router.push("/denied");
        }
        mode = token_payload.mode ? token_payload.mode : mode;
        email = token_payload.user_email_id ? token_payload.user_email_id : email;
        quizName = token_payload.name ? token_payload.name : quizName;

      } catch (validation_err) {
        console.error(`validation error: ${validation_err}`);
        if (validation_err.response.status < 500) {
          return router.push("/denied");
        }
      }
    }

    try {
      // load quiz and results
      const quizResponse = await axios.get(
        `${host}/api/quizzes/quiz?name=${encodeURIComponent(quizName)}`
      );

      // load quiz and results
      const resultResponse = await axios.get(
        `${host}/api/results/result?email=${encodeURIComponent(email)}`
      );

      // get questions in randomize order
      const quizQuestionsShuffled = quizResponse.data[0].order === "normal" ?  quizResponse.data[0].questions :_.shuffle(quizResponse.data[0].questions);

      // get first question
      const firstKey = Object.keys(quizQuestionsShuffled)[0];
      const firstQuestionId = quizQuestionsShuffled[firstKey];

      // load question
      const singleQuestionResponse = await axios.get(
        `${host}/api/questions/question?id=${encodeURIComponent(
          firstQuestionId
        )}`
      );

      // get answers of question in randomize order
      const singleQuestionShuffled = helpers.getQuestionShuffled(
        singleQuestionResponse
      );

      // save to state
      commit("storeQuiz", quizResponse.data);
      commit("storeResults", resultResponse.data);
      commit("storeQuestionsIds", quizQuestionsShuffled);
      commit("storeQuizQuestion", singleQuestionShuffled);
      commit("setQuizDataLoaded", true);
      commit("setEmail", email);
      commit("setAppMode", mode);
    } catch (error) {
      throw "error in api call " + error;
    }
  },
  async createResult(context, resultData) {
    axios.post(process.env.VUE_APP_BACKEND_HOST + "/api/results/", resultData);
  },
  nextQuestion({ state, getters, commit, dispatch }) {
    dispatch("finish");

    if (!getters.isLastQuestion) {
      commit("setSuccess", null);
      const host = process.env.VUE_APP_BACKEND_HOST;

      commit("setQuizDataLoaded", false);
      commit("incrementQuestionsCounter");
      const qid = Object.values(getters.questionsIds)[state.questionsCounter];

      // load question

      axios.get(host + "/api/questions/question?id=" + qid).then((response) => {
        const singleQuestionShuffled = helpers.getQuestionShuffled(response);
        commit("storeQuizQuestion", singleQuestionShuffled);
        commit("setQuizDataLoaded", true);
      });
    }
  },
  finish({ getters, commit, dispatch }) {
    if (getters.isLastQuestion) {
      commit("setQuizFinish", true);
      dispatch("showResults");
    }
  },
  startConfetti({ getters }, timeout = 1500) {
    if (getters.scorePercentage >= 70) {
      this._vm.$confetti.start({ defaultDropRate: 30 });
      setTimeout(() => this._vm.$confetti.stop(), timeout);
    }
  },
  showResults() {
    return setTimeout(() => router.push("/results"), 1500);
  },
  handleResult({ commit, getters }, onSuccess = null) {
    if (onSuccess === null) {
      return false;
    }
    if (onSuccess) {
      commit("setQuestionsCorrect", getters.currentQuestion._id.$oid);
      return commit("setSuccess", true);
    }
    commit("setQuestionsFailed", getters.currentQuestion._id.$oid);
    return commit("setSuccess", false);
  },
};

export default actions;
