const getters = {
  // quiz
  isExamMode: (state) => {
    return state.appMode === 'exam'
  },

  // questions
  questionsIds: (state) => {
    return state.questionsIds
  },
  currentQuestion: (state) => {
    return state.quizQuestion
  },
  correctAnswers: (state) => {
    return state.quizQuestion ? state.quizQuestion.correctAnswers : null
  },
  buttonColor: (state) => {
    if (state.isSuccess === true) {
      return {
        color: 'success',
        label: 'Correct',
      }
    }
    if (state.isSuccess === false) {
      return {
        color: 'error',
        label: 'Incorrect',
      }
    }
    return {
      color: 'primary',
      label: 'Submit',
    }
  },
  isSuccess: (state) => {
    return state.isSuccess
  },
  isFeedbackAllowed: (state, getters) => {
    return state.isSuccess !== null && getters.isExamMode === false
  },
  questionsCorrectCount: (state) => {
    return state.questionsCorrect.length
  },
  questionsCorrect: (state) => {
    return state.questionsCorrect
  },
  questionsFailedCount: (state) => {
    return state.questionsFailed.length
  },
  questionsFailed: (state) => {
    return state.questionsFailed
  },
  isLastQuestion: (state) => {
    return state.questionsCounter === state.quizQuestionsLength - 1
      ? true
      : false
  },

  // results
  scorePercentage: (state) => {
    const correct = state.questionsCorrect.length
    const total = state.quizQuestionsLength
    const result = parseInt(((correct / total) * 100).toFixed())

    return Number.isNaN(result) ? 0 : result
  },
}

export default getters
