<template>
  <v-container>
    <div class="mx-1 pa-4 rounded-lg elevation-2 box">
      <!-- question -->
      <v-row justify="center">
        <v-col cols="12">
          <h2 class="headline">
            <VueShowdown :markdown="currentQuestion.question" flavor="github"
              :options="{ emoji: true, openLinksInNewWindow: true }" />
          </h2>
        </v-col>
      </v-row>

      <!-- syntax highlighter -->
      <v-row v-if="currentQuestion.code.script !== ''" justify="center">
        <v-col cols="12" md="12">
          <highlight-code :code="currentQuestion.code.script" auto></highlight-code>
        </v-col>
      </v-row>

      <!-- answers single choice -->
      <v-row justify="center" v-if="currentQuestion.correctAnswers.length === 1">
        <v-col cols="12" md="12">
          <v-radio-group v-model="radioGroup">
            <div v-for="(answer, n) in currentQuestion.answers" :key="n"
              class="answer-option d-flex align-center px-4 my-1 rounded-lg" :class="{
              successBox:
                isFeedbackAllowed && checkSelectedSingle(answer) === true,
              errorBox:
                isFeedbackAllowed && checkSelectedSingle(answer) === false,
              greenBackground:
                isFeedbackAllowed && checkSelectedSingle(answer) === null,
              disableClick: isSuccess != null,
            }">
              <v-radio :value="answer">
                <template slot="label">
                  <VueShowdown :markdown="answer" flavor="github" :options="{ emoji: true, openLinksInNewWindow: true }"
                    style="position: relative; top: 7px" />
                </template>
              </v-radio>

              <v-icon v-if="isFeedbackAllowed && checkSelectedSingle(answer) === false
              " color="error" class="ml-6 flex justify-end">
                mdi-close-thick
              </v-icon>

              <v-icon v-if="(isFeedbackAllowed && checkSelectedSingle(answer)) ||
              (isFeedbackAllowed && checkSelectedSingle(answer) === null)
              " color="success" class="ml-6 flex justify-end">
                mdi-check-bold
              </v-icon>
            </div>
          </v-radio-group>
        </v-col>
      </v-row>

      <!-- answers multi choice -->
      <v-row justify="center">
        <v-col v-if="currentQuestion.correctAnswers.length > 1" cols="12" md="6">
          <div v-for="(answer, n) in currentQuestion.answers" :key="n"
            class="answer-option d-flex align-center px-4 my-1 rounded-lg" :class="{
              successBox:
                isFeedbackAllowed && checkSelectedMulti(answer) === true,
              errorBox:
                isFeedbackAllowed && checkSelectedMulti(answer) === false,

              disableClick: isSuccess != null,
            }">
            <v-checkbox v-model="selected" :key="n" :value="answer">
              <template slot="label">
                <VueShowdown :markdown="answer" flavor="github" :options="{ emoji: true, openLinksInNewWindow: true }"
                  style="position: relative; top: 7px" />
              </template>
            </v-checkbox>

            <v-icon v-if="isFeedbackAllowed && checkSelectedMulti(answer) === false" color="error"
              class="ml-6 flex justify-end">
              mdi-close-thick
            </v-icon>

            <!-- <v-icon
              v-if="
                (isFeedbackAllowed && checkSelectedSingle(answer)) ||
                (isFeedbackAllowed && checkSelectedSingle(answer) === null)
              "
              color="success"
              class="ml-6 flex justify-end"
            >
              mdi-check-bold
            </v-icon> -->
          </div>
        </v-col>
      </v-row>

      <!-- submit button -->
      <v-row v-if="isSuccess === null" justify="center" class="mb-8">
        <v-col cols="12" md="1">
          <v-btn class="mr-4 primary" @click="checkAnswerMcq()"> Submit </v-btn>
        </v-col>
      </v-row>

      <!-- explanation field -->
      <transition name="fade">
        <div v-if="!(isSuccess === null)">
          <div v-if="isSuccess === false && isExamMode === false">
            <!-- Explanation -->
            <div class="ma-4 d-flex align-start" v-if="!!currentQuestion.explanation">
              <v-icon left color="green darken-2"> mdi-information </v-icon>

              <VueShowdown :markdown="currentQuestion.explanation" flavor="github"
                :options="{ emoji: true, openLinksInNewWindow: true }" />
            </div>

            <!-- Doc link -->
            <div class="ma-4 d-flex align-start" v-if="!!currentQuestion.documentationLink">
              <v-icon left color="green darken-2">
                mdi-book-open-page-variant-outline
              </v-icon>
              <a :href="currentQuestion.documentationLink" target="_blank">
                Documentation
              </a>
            </div>
          </div>

          <!-- response bar -->
          <div class="mb-8 d-flex align-center justify-center">
            <!-- alert response -->
            <v-alert :type="buttonColor.color" class="mr-4 mb-0">
              {{ buttonColor.label }}
            </v-alert>

            <!-- next button -->

            <v-btn x-large @click="nextQuestion()"> Next </v-btn>
          </div>
        </div>
      </transition>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { VueShowdown } from "vue-showdown";
export default {
  name: "MultipleChoice",
  components: { VueShowdown },
  data() {
    return {
      radioGroup: null,
      selected: [],
    };
  },
  methods: {
    checkSelectedSingle: function (selection) {
      let include = this.correctAnswers.includes(selection);
      let selected = this.radioGroup === selection ? true : false;

      // correct selected
      if (include && selected) {
        return true;
      }
      // false selected
      if (!include && selected) {
        return false;
      }
      // not selected
      if (include && !selected) {
        return null;
      }
    },

    checkSelectedMulti: function (selection) {
      let include = this.correctAnswers.includes(selection);
      let selected = this.selected.includes(selection);

      // correct selected
      if (include && selected) {
        return true;
      }
      // false selected
      if (!include && selected) {
        return false;
      }
      // not selected
      if (include && !selected) {
        return true;
      }
    },

    checkAnswerMcq() {
      if (this.currentQuestion.correctAnswers.length === 1) {
        if (this.radioGroup !== null) {
          const correctAnswer = this.correctAnswers[0];
          const selectedAnswer = this.radioGroup;

          // handle result
          if (selectedAnswer === correctAnswer) {
            return this.$store.dispatch("handleResult", true);
          }
          return this.$store.dispatch("handleResult", false);
        }
      } else {
        const correctAnswers = this.correctAnswers;
        let correctAnswersLength = correctAnswers.length;

        this.selected.forEach((item) => {
          if (correctAnswers.includes(item)) {
            correctAnswersLength--;
          } else {
            correctAnswersLength++;
          }
        });

        // handle result
        if (correctAnswersLength === 0) {
          return this.$store.dispatch("handleResult", true);
        }

        return this.$store.dispatch("handleResult", false);
      }
    },

    nextQuestion() {
      this.radioGroup = null;
      this.$store.dispatch("nextQuestion");
    },
  },
  computed: {
    ...mapGetters([
      "currentQuestion",
      "buttonColor",
      "isSuccess",
      "isExamMode",
      "isFeedbackAllowed",
      "correctAnswers",
    ]),
  },
};
</script>

<style lang="scss">
.successBox.rounded-lg {
  border: 2px solid #4caf50;
}

.errorBox.rounded-lg {
  border: 2px solid #ff5252;
}

.v-radio {
  margin-bottom: 0px !important;
}

.disableClick {
  pointer-events: none;
}

.greenBackground {
  background-color: #e5ffe6;
}
</style>
