import Vue from 'vue'
import Vuex from 'vuex'
import VueConfetti from 'vue-confetti'
import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

Vue.use(Vuex)
Vue.use(VueConfetti)

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
})
