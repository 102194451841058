const state = {
  quizDataLoaded: false,
  appError: false,
  appMode: null,

  // quiz
  quizzes: null,
  quizQuestion: null,
  quizQuestionsLength: 0,
  quizFinish: false,
  isSuccess: null,

  // question
  questionsIds: null,
  questionsCounter: 0,
  questionsCorrect: [],
  questionsFailed: [],

  // result
  results: null,
  email: null,
}

export default state