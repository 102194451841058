<template>
  <!-- quiz -->
  <v-container v-if="state.quizDataLoaded">

    <!-- progress bar -->
    <div class="px-4">
      <v-col cols="12" class="text-right light-blue--text">{{
        progressBarLeft
      }}</v-col>
      <v-progress-linear :value="progressBarPercent"></v-progress-linear>
    </div>

    <!-- quiz types -->
    <MultipleChoice v-if="currentType === 'MCQ'"></MultipleChoice>
    <DiscreteChoice v-if="currentType === 'DOMC'"></DiscreteChoice>
  </v-container>

  <!-- app error -->
  <span v-else-if="state.appError"></span>

  <!-- progress loader -->
  <progress-loader v-else>Loading question...</progress-loader>
</template>

<script>
import { mapGetters } from 'vuex'
import MultipleChoice from '@/components/MultipleChoice'
import DiscreteChoice from '@/components/DiscreteChoice'
import ProgressLoader from '@/components/base/ProgressLoader'

export default {
  name: 'Quiz',
  components: { ProgressLoader, MultipleChoice, DiscreteChoice },
  data: () => ({
    types: ['DOMC', 'MCQ', 'Random'],
  }),
  methods: {
    randomQuiz: function() {
      let randNr = Math.floor(Math.random() * 2)
      return this.types[randNr]
    },
    checkRandom: function() {
      return this.types[this.currentQuestion.type] === 'Random'
    },
  },
  computed: {
    ...mapGetters(['currentQuestion']),
    state: function() {
      return this.$store.state
    },
    currentType: function() {
      if (this.checkRandom()) {
        return this.randomQuiz()
      }
      return this.types[this.currentQuestion.type]
    },
    progressBarLeft: function() {
      if (this.$store.state.quizFinish) {
        return '0 left of ' + this.$store.state.quizQuestionsLength
      }
      return (
        this.$store.state.quizQuestionsLength -
        this.$store.state.questionsCounter +
        ' left of ' +
        this.$store.state.quizQuestionsLength
      )
    },
    progressBarPercent: function() {
      if (this.$store.state.quizFinish) {
        return 100
      }
      return Math.round(
        100 -
          ((this.$store.state.quizQuestionsLength -
            this.$store.state.questionsCounter) /
            this.$store.state.quizQuestionsLength) *
            100
      )
    },
  },
}
</script>

<style lang="sass">
.v-main
  background: #eee

.box
  background: white

.hljs
    padding: 10px !important
    background-color: #282a36 !important 
    color: #f8f8f2 !important
</style>
