<template>
  <v-container>
    <!-- title -->
    <v-row class="mb-12">
      <v-col cols="12">
        <h1 class="font-weight-light mb-2 headline" v-text="`Quiz Results`" />
        <span class="font-weight-light subtitle-1">
          check out your statistics.
        </span>
      </v-col>
    </v-row>

    <!-- stats-cards -->
    <v-row class="mb-12">
      <!-- total -->
      <v-col cols="3">
        <base-material-stats-card
          color="cyan"
          icon="mdi-circle-outline"
          title="Total"
          :value="this.$store.state.quizQuestionsLength"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>

      <!-- correct -->
      <v-col cols="3">
        <base-material-stats-card
          color="success"
          icon="mdi-check"
          title="Correct"
          :value="this.$store.state.questionsCorrect.length"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>

      <!-- failed -->
      <v-col cols="3">
        <base-material-stats-card
          color="error"
          icon="mdi-close"
          title="Failed"
          :value="this.$store.state.questionsFailed.length"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>

      <!-- percentage-->
      <v-col cols="3">
        <base-material-stats-card
          color="error"
          icon="mdi-percent"
          title="Score"
          :value="this.scorePercentage"
          sub-icon="mdi-clock"
          sub-text="Just Updated"
        />
      </v-col>
    </v-row>

    <!-- progress loader -->
    <progress-loader v-if="!questions">Loading question...</progress-loader>

    <template v-if="questions">
      <!-- tables by topic -->
      <v-row v-for="(topic, i) in topics" :key="i" class="mb-12">
        <v-col cols="12">
          <result-topic-table
            :title="topic"
            :questions="questions"
          ></result-topic-table>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import axios from "axios";
import ResultTopicTable from "@/components/base/ResultTopicTable";
import ProgressLoader from "@/components/base/ProgressLoader";
export default {
  name: "QuizResult",
  components: { ResultTopicTable, ProgressLoader },
  data: () => ({
    questions: null,
  }),
  mounted() {
    // trigger confetti
    this.$store.dispatch("startConfetti");

    // load async data
    this.loadAsyncQuestions();

    // post result to db if exists
    if (this.$store.state.results) {
      this.createResult();
    }
  },
  methods: {
    async loadAsyncQuestions() {
      // get data from vuex
      let questionsCorrect = Object.values(this.questionsCorrect);

      // load correct questions via ajax
      if (questionsCorrect.length > 0) {
        const postData = {
          // type: 'questions',
          data: questionsCorrect,
        };
        const host = process.env.VUE_APP_BACKEND_HOST;
        await axios
          .post(host + "/api/questions/questions_by_ids", postData)
          .then((result) => {
            questionsCorrect = result.data;
          });
      }

      // get data from vuex
      let questionsFailed = Object.values(this.questionsFailed);

      // load failed questions via ajax
      if (questionsFailed.length > 0) {
        const postData = {
          // type: 'questions',
          data: questionsFailed,
        };
        const host = process.env.VUE_APP_BACKEND_HOST;
        await axios
          .post(host + "/api/questions/questions_by_ids", postData)
          .then((result) => {
            questionsFailed = result.data;
          });
      }

      this.questions = { failed: questionsFailed, correct: questionsCorrect };
    },
    createResult() {
      const postData = {
        data: {
          result: {
            total: this.$store.state.quizQuestionsLength,
            correct: this.$store.state.questionsCorrect,
            failed: this.$store.state.questionsFailed,
          },
        },
      };
      postData.data.email = this.$store.state.email;
      postData.data.score = this.scorePercentage;
      this.$store.dispatch("createResult", postData);
    },
  },
  computed: {
    ...mapGetters([
      "questionsCorrect",
      "questionsCorrectCount",
      "questionsFailed",
      "questionsFailedCount",
      "scorePercentage",
    ]),
    topics() {
      let topicsCorrect = [];
      let topicsFailed = [];

      if (this.questions) {
        topicsCorrect = _.map(this.questions.correct, "topic");
        topicsFailed = _.map(this.questions.failed, "topic");
      }
      const topics = _.union(topicsCorrect.concat(topicsFailed));
      return topics;
    },
  },
};
</script>

<style scoped></style>
