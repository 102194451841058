import Vue from "vue";
import VueRouter from "vue-router";
import Quiz from "@/views/Quiz";
import QuizResult from "@/views/QuizResult";
import AppError from "@/components/base/AppError";

Vue.use(VueRouter);

const routes = [
  {
    path: "/results",
    name: "QuizResult",
    component: QuizResult,
  },
  {
    path: "/quiz",
    name: "Quiz",
    component: Quiz,
  },
  {
    path: "/denied",
    component: AppError,
    meta: { message: "No Access" },
  },
];

const router = new VueRouter({
  routes,
});

export default router;
