<template>
  <v-container>
    <div class="mx-1 pa-4 rounded-lg elevation-2 box">
      <!-- question -->
      <v-row justify="center">
        <v-col cols="12">
          <h2 class="headline mb-3 text-center">
            <VueShowdown :markdown="currentQuestion.question" flavor="github"
              :options="{ emoji: true, openLinksInNewWindow: true }" />
          </h2>
        </v-col>

        <!-- syntax highlighter -->
        <v-col cols="12" md="8">
          <highlight-code class="pa-4" v-if="currentQuestion.code.script !== ''" :code="currentQuestion.code.script"
            auto></highlight-code>
        </v-col>
      </v-row>

      <!-- answer -->
      <v-row justify="center" class="text-center">
        <v-col cols="12" md="12">
          <h2>
            <VueShowdown :markdown="answer" flavor="github" :options="{ emoji: true, openLinksInNewWindow: true }" />
          </h2>
        </v-col>
      </v-row>

      <!-- yes / no buttons -->
      <v-row justify="center" v-if="isSuccess === null">
        <v-col cols="6" class="text-right">
          <v-btn outlined @click="checkAnswerDomc(true, answer)"> Yes </v-btn>
        </v-col>
        <v-col cols="6" class="text-left">
          <v-btn outlined @click="checkAnswerDomc(false, answer)"> No </v-btn>
        </v-col>
      </v-row>

      <transition name="fade">
        <div v-if="!(isSuccess === null)">
          <!-- Explanation -->
          <div class="ma-4 d-flex align-start" v-if="!!currentQuestion.explanation">
            <v-icon left color="green darken-2"> mdi-information </v-icon>
            <VueShowdown :markdown="currentQuestion.explanation" flavor="github"
              :options="{ emoji: true, openLinksInNewWindow: true }" />
          </div>

          <!-- Doc link -->
          <div class="ma-4 d-flex align-start" v-if="!!currentQuestion.documentationLink">
            <v-icon left color="green darken-2">
              mdi-book-open-page-variant-outline
            </v-icon>
            <a :href="currentQuestion.documentationLink" target="_blank">
              Documentation
            </a>
          </div>

          <!-- response bar -->
          <v-row>
            <v-col class="mt-8 d-flex justify-center" cols="12">
              <!-- alert response -->
              <v-alert v-if="isFeedbackAllowed" :type="buttonColor.color" class="mr-4">
                {{ buttonColor.label }}
              </v-alert>

              <!-- next button -->
              <v-btn v-if="isSuccess !== null" x-large @click="nextQuestion()">
                Next
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </transition>
    </div>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

import { VueShowdown } from "vue-showdown";
export default {
  name: "DiscreteChoice",
  components: { VueShowdown },
  data: () => ({
    questionCounter: 0,
    correctCounter: 0,
  }),
  methods: {
    resetCounters() {
      this.correctCounter = 0;
      this.questionCounter = 0;
    },
    checkAnswerDomc(btn, answer) {
      if (this.isSuccess !== null) {
        return false;
      }

      const correctAnswers = this.$store.getters.correctAnswers;
      let isCorrect = correctAnswers.includes(answer) === btn;

      if (isCorrect) {
        if (correctAnswers.includes(answer)) {
          this.correctCounter++;

          // finish this question
          if (this.correctCounter === correctAnswers.length) {
            return this.$store.dispatch("handleResult", true);
          }
        }
        this.incrementQuestionCounter();
      } else {
        // finish this question
        return this.$store.dispatch("handleResult", false);
      }
    },
    incrementQuestionCounter() {
      if (
        this.questionCounter <
        Object.keys(this.currentQuestion.answers).length - 1
      ) {
        this.questionCounter++;
      }
    },
    nextQuestion() {
      if (!this.isLastQuestion) {
        this.resetCounters();
      }
      this.$store.dispatch("nextQuestion");
    },
  },
  computed: {
    ...mapGetters([
      "currentQuestion",
      "buttonColor",
      "isSuccess",
      "isFeedbackAllowed",
      "isExamMode",
      "isLastQuestion",
    ]),
    answer: function () {
      return this.currentQuestion.answers[this.questionCounter];
    },
  },
};
</script>

<style lang="sass" scoped></style>
