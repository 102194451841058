var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"mx-1 pa-4 rounded-lg elevation-2 box"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('h2',{staticClass:"headline"},[_c('VueShowdown',{attrs:{"markdown":_vm.currentQuestion.question,"flavor":"github","options":{ emoji: true, openLinksInNewWindow: true }}})],1)])],1),(_vm.currentQuestion.code.script !== '')?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('highlight-code',{attrs:{"code":_vm.currentQuestion.code.script,"auto":""}})],1)],1):_vm._e(),(_vm.currentQuestion.correctAnswers.length === 1)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-radio-group',{model:{value:(_vm.radioGroup),callback:function ($$v) {_vm.radioGroup=$$v},expression:"radioGroup"}},_vm._l((_vm.currentQuestion.answers),function(answer,n){return _c('div',{key:n,staticClass:"answer-option d-flex align-center px-4 my-1 rounded-lg",class:{
            successBox:
              _vm.isFeedbackAllowed && _vm.checkSelectedSingle(answer) === true,
            errorBox:
              _vm.isFeedbackAllowed && _vm.checkSelectedSingle(answer) === false,
            greenBackground:
              _vm.isFeedbackAllowed && _vm.checkSelectedSingle(answer) === null,
            disableClick: _vm.isSuccess != null,
          }},[_c('v-radio',{attrs:{"value":answer}},[_c('template',{slot:"label"},[_c('VueShowdown',{staticStyle:{"position":"relative","top":"7px"},attrs:{"markdown":answer,"flavor":"github","options":{ emoji: true, openLinksInNewWindow: true }}})],1)],2),(_vm.isFeedbackAllowed && _vm.checkSelectedSingle(answer) === false
            )?_c('v-icon',{staticClass:"ml-6 flex justify-end",attrs:{"color":"error"}},[_vm._v(" mdi-close-thick ")]):_vm._e(),((_vm.isFeedbackAllowed && _vm.checkSelectedSingle(answer)) ||
            (_vm.isFeedbackAllowed && _vm.checkSelectedSingle(answer) === null)
            )?_c('v-icon',{staticClass:"ml-6 flex justify-end",attrs:{"color":"success"}},[_vm._v(" mdi-check-bold ")]):_vm._e()],1)}),0)],1)],1):_vm._e(),_c('v-row',{attrs:{"justify":"center"}},[(_vm.currentQuestion.correctAnswers.length > 1)?_c('v-col',{attrs:{"cols":"12","md":"6"}},_vm._l((_vm.currentQuestion.answers),function(answer,n){return _c('div',{key:n,staticClass:"answer-option d-flex align-center px-4 my-1 rounded-lg",class:{
            successBox:
              _vm.isFeedbackAllowed && _vm.checkSelectedMulti(answer) === true,
            errorBox:
              _vm.isFeedbackAllowed && _vm.checkSelectedMulti(answer) === false,

            disableClick: _vm.isSuccess != null,
          }},[_c('v-checkbox',{key:n,attrs:{"value":answer},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_c('template',{slot:"label"},[_c('VueShowdown',{staticStyle:{"position":"relative","top":"7px"},attrs:{"markdown":answer,"flavor":"github","options":{ emoji: true, openLinksInNewWindow: true }}})],1)],2),(_vm.isFeedbackAllowed && _vm.checkSelectedMulti(answer) === false)?_c('v-icon',{staticClass:"ml-6 flex justify-end",attrs:{"color":"error"}},[_vm._v(" mdi-close-thick ")]):_vm._e()],1)}),0):_vm._e()],1),(_vm.isSuccess === null)?_c('v-row',{staticClass:"mb-8",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{staticClass:"mr-4 primary",on:{"click":function($event){return _vm.checkAnswerMcq()}}},[_vm._v(" Submit ")])],1)],1):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(!(_vm.isSuccess === null))?_c('div',[(_vm.isSuccess === false && _vm.isExamMode === false)?_c('div',[(!!_vm.currentQuestion.explanation)?_c('div',{staticClass:"ma-4 d-flex align-start"},[_c('v-icon',{attrs:{"left":"","color":"green darken-2"}},[_vm._v(" mdi-information ")]),_c('VueShowdown',{attrs:{"markdown":_vm.currentQuestion.explanation,"flavor":"github","options":{ emoji: true, openLinksInNewWindow: true }}})],1):_vm._e(),(!!_vm.currentQuestion.documentationLink)?_c('div',{staticClass:"ma-4 d-flex align-start"},[_c('v-icon',{attrs:{"left":"","color":"green darken-2"}},[_vm._v(" mdi-book-open-page-variant-outline ")]),_c('a',{attrs:{"href":_vm.currentQuestion.documentationLink,"target":"_blank"}},[_vm._v(" Documentation ")])],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"mb-8 d-flex align-center justify-center"},[_c('v-alert',{staticClass:"mr-4 mb-0",attrs:{"type":_vm.buttonColor.color}},[_vm._v(" "+_vm._s(_vm.buttonColor.label)+" ")]),_c('v-btn',{attrs:{"x-large":""},on:{"click":function($event){return _vm.nextQuestion()}}},[_vm._v(" Next ")])],1)]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }